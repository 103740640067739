import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import _ from "lodash"
import log from "loglevel"
import React from "react"
import ButtonList from "../components/button-list"
import CompatibleProducts from "../components/compatible-products"
import FAQs from "../components/faqs"
import ImageGridWithZoom from "../components/image-grid-zoom"
import Layout from "../components/layout"
import Notes from "../components/notes"
import PhaseOutWarning from "../components/phase-out-warning"
import RefrigerantSeries from "../components/refrigerant-series"
import TitleWithCopyLink from "../components/title-copy-link"
import { numberWithCommas } from "../utils/formatting"

const IndoorUnit = ({ data, pageContext }) => {
  const product = data.product.nodes[0] || {}
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategoryId = pageContext.subcategoryId
  const faqs = data.faqs.nodes

  product.image = data.images.edges.length && data.images.edges[0].node.parent

  product.models.forEach(model => {
    if (model.submittalSheet) {
      model.submittalSheet.path = (
        (
          data.submittalSheet.edges.find(
            edge => edge.node.name === model.modelNumber
          ) || {}
        ).node || {}
      ).publicURL
    }
  })

  const compatibleControls = data.compatibleControls.nodes || []

  log.info(product, data, faqs, pageContext)

  return (
    <Layout title={product.name} navItems={pageContext.categories || []}>
      <div className="uk-section-xsmall">
        <div className="uk-container uk-container-large">
          <ul className="uk-breadcrumb">
            <li>
              <span>{category.name}</span>
            </li>
            <li>
              <Link
                to={`/category/${category._id}/sub/${selectedSubcategoryId}`}
              >
                {product.category}
              </Link>
            </li>
            <li>
              <span>{product.productSeries}</span>
            </li>
          </ul>
          <div className="uk-section-xsmall">
            <div className="uk-container uk-container-large uk-text-center">
              {/* <h2>{category.name}</h2> */}
              <ButtonList
                items={subcategories}
                parentId={category._id}
                selectedItemId={selectedSubcategoryId}
              ></ButtonList>
            </div>
          </div>
          <div className="product uk-section-xsmall">
            <h1 className="uk-text-center white-space-pre-line">
              {product.name}
            </h1>
            {/* <h4 className="uk-text-center">{product.subcategory}</h4> */}
            <p className="uk-text-center">
              <span className="uk-text-bold">{product.capacityText}</span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span className="uk-text-bold">Model: </span>
              {product.productSeries}
            </p>
            <RefrigerantSeries value={product.refrigerantSeries} />
            <PhaseOutWarning
              categoryId={category._id}
              warning={product.phaseOutWarning}
              replacement={product.replacementProductSeries}
            />
          </div>

          <ImageGridWithZoom
            images={[product.image]}
            title={""}
            maxWidth={"400px"}
            section={false}
          ></ImageGridWithZoom>

          {["fxsq_t", "fxmq_t"].includes(product._id) ? (
            <h4 className="uk-text-center">
              <span className="" data-uk-icon="icon:warning; ratio: 1.5"></span>{" "}
              <a href="#faqs">Recent model number update</a>
            </h4>
          ) : (
            <></>
          )}

          <div className="uk-section-xsmall">
            <h3 className="uk-text-bold uk-text-center"></h3>
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-striped uk-text-center uk-table-small">
                <thead className="thead-primary uk-text-center">
                  <tr>
                    <th>Model Number</th>
                    <th>Nominal Size (MBH)</th>
                    <th>CFM (High/Low)</th>
                    <th>Nominal Cooling (BTU/hr)</th>
                    <th>Nominal Heating (BTU/hr)</th>
                    <th>Dimensions (HxWxD)</th>
                    <th>Voltage</th>
                    <th>MCA (A)</th>
                    <th>MOP (A)</th>
                  </tr>
                </thead>
                <tbody>
                  {product.models.map((model, i) => (
                    <tr key={`main_table_tr_${i}`}>
                      <td>{model.modelNumber}</td>
                      <td>{model.capacity}</td>
                      <td className="text-no-wrap">{model.CFM}</td>
                      <td>{numberWithCommas(model.cooling)}</td>
                      <td>{numberWithCommas(model.heating)}</td>
                      <td className="text-no-wrap">{model.dimensions}</td>
                      <td className="text-no-wrap">{model.powerSupply}</td>
                      <td>{model.MCA}</td>
                      <td>{model.MOP}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {(product.notes || []).length ? (
              <div className="uk-margin-top">
                <h5>Notes</h5>
                <div className="uk-text-meta">
                  <ul>
                    {product.notes.map((feature, i) => (
                      <li key={`note_${i}`}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* <div className="uk-flex uk-flex-center">
              <Notes title={"Features"} items={product.features} />
              <div style={{ maxWidth: "30%" }}>
                <Advertisement vertical={true}></Advertisement>
              </div>
            </div> */}

          <Notes title={"Features"} items={product.features} />

          <CompatibleProducts
            title="Compatible Controls"
            category="Thermostats"
            products={compatibleControls}
            images={data.controlsImages.edges}
          ></CompatibleProducts>

          {/* <CompatibleProducts
            title="Compatible Remotes"
            category="Remotes"
            products={compatibleControls}
            images={data.controlsImages.edges}
          ></CompatibleProducts> */}

          <CompatibleProducts
            title="Compatible Adaptors"
            category="Adaptors"
            products={compatibleControls}
            images={data.controlsImages.edges}
          ></CompatibleProducts>

          <div className="uk-section-xsmall">
            <TitleWithCopyLink title={"Submittal Data Sheets"} type="h3" />

            <div className="uk-overflow-auto">
              <table
                className="uk-table uk-table-striped  uk-table-small same-width-td uk-margin-auto"
                style={{ maxWidth: "400px" }}
              >
                <thead className="thead-primary">
                  <tr>
                    <th></th>
                    {_.uniq(product.models.map(model => model.powerSupply)).map(
                      (powerSupply, i) => (
                        <th key={`ps_${i}`}>{powerSupply}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {_.uniq(product.models.map(model => model.capacity)).map(
                    (capacity, i) => {
                      const electricals = _.uniq(
                        product.models.map(model => model.powerSupply)
                      )
                      return (
                        <tr key={`submittalSheets_row_${i}`}>
                          <td className="uk-text-bold text-dark">{capacity}</td>
                          {electricals.map((electrical, j) => {
                            const model = product.models.find(
                              _model =>
                                _model.powerSupply === electrical &&
                                _model.capacity === capacity &&
                                _model.submittalSheet &&
                                _model.submittalSheet.path
                            )
                            return (
                              <td key={`submittalSheets_col_${j}`}>
                                {((model || {}).submittalSheet || {}).path ? (
                                  <OutboundLink
                                    href={model.submittalSheet.path}
                                    target="_blank"
                                  >
                                    {model.modelNumber}
                                  </OutboundLink>
                                ) : (
                                  ""
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <FAQs items={faqs} productSeries={product.productSeries}></FAQs>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndoorUnitProductAndImage(
    $productId: String
    $imageId: String
    $submittalSheetIds: [String]
    $categoryRegex: String
    $subcategoryRegex: String
    $controlsCompatibility: String
  ) {
    images: allImageSharp(filter: { original: { src: { regex: $imageId } } }) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    submittalSheet: allFile(
      filter: { extension: { eq: "pdf" }, name: { in: $submittalSheetIds } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    faqs: allFaqsJson(
      filter: {
        categoryId: { regex: $categoryRegex }
        subcategoryId: { regex: $subcategoryRegex }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    product: allProductsJson(filter: { _id: { eq: $productId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        name
        productId
        productSeries
        features
        notes
        subcategory
        width
        refrigerantSeries
        replacementProductSeries
        phaseOutWarning
        models {
          capacity
          CFM
          MCA
          MOP
          dimensions
          cooling
          heating
          modelNumber
          name
          powerSupply
          productSeries
          submittalSheet {
            url
            fileName
            type
          }
        }
      }
    }
    compatibleControls: allProductsJson(
      filter: { subcategory: { eq: $controlsCompatibility } }
    ) {
      nodes {
        _id
        category
        capacity
        capacityText
        name
        productId
        productSeries
        subcategory
        description
        cardFeatures
      }
    }
    controlsImages: allImageSharp(
      filter: { original: { src: { regex: "/.*controls/i" } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...squareFluidImage
          }
        }
      }
    }
  }
`

export default IndoorUnit
